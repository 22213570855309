export default {
  // eslint-disable-next-line global-require
  template: require('./partners.vue'),
  name: 'partner',
  props: {
    partnerObj: Array,
  },
  provide() {
  },
};
