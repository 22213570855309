import Vue from 'vue';
import App from './app.vue';

import './assets/styles/app.scss';

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  components: { App },
  template: '<app/>',
});
