var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "content-partner" }, [
    _c("div", { staticClass: "category" }, [
      _vm._v(
        _vm._s(
          _vm.partnerObj[0].replace(/^./, _vm.partnerObj[0][0].toUpperCase())
        )
      ),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.partnerObj[1], function (vertical, key) {
        return _c("li", { key: vertical.public_name + "_" + key }, [
          _vm._v("\n      " + _vm._s(vertical.public_name) + "\n    "),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }