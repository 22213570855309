var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticStyle: { margin: "50px" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "center" }, [
      _c("span", { staticClass: "footer-text" }, [
        _vm._v(
          "© " +
            _vm._s(new Date().getFullYear()) +
            " ADCLICK. TODOS OS DIREITOS RESERVADOS."
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "center", staticStyle: { "margin-bottom": "15px" } },
      [
        _c("img", {
          staticStyle: { width: "30px", height: "auto" },
          attrs: { src: require("../assets/img/logo.png"), alt: "Adclick" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }