import axios from 'axios';
import axiosRetry from 'axios-retry';
import Partners from './components/partners.vue';
import Footer from './components/footer.vue';

const BASE_URL = process.env.VERTICAL_CLIENT_URL || '';

const AXIOS_CONFIG = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  'axios-retry': {
    retries: 2,
  },
};

axiosRetry(axios);

export default {
  // eslint-disable-next-line global-require
  template: require('./app.vue'),
  name: 'app',
  components: {
    partners: Partners,
    'my-footer': Footer,
  },
  data() {
    return {
      partners: null,
      partnersList: [],
      loading: true,
      errored: false,
    };
  },
  mounted() {
    const queryUrl = BASE_URL;
    const config = { ...AXIOS_CONFIG };
    axios
      .get(queryUrl, config)
      .then((response) => {
        this.partners = Object.entries(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.errored = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {},
  computed: {},
};
