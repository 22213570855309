var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "app" } }, [
    _vm.loading
      ? _c("div", { staticClass: "center" }, [
          _c("img", {
            attrs: {
              alt: "Loading....",
              src: require("./assets/img/loader.gif"),
              width: "250",
            },
          }),
        ])
      : _c(
          "div",
          [
            _c("h3", [_vm._v("PARCEIROS ADCLICK")]),
            _vm._v(" "),
            _vm._l(_vm.partners, function (partner, key) {
              return _c(
                "div",
                { key: partner[0] + "_" + key, staticClass: "content" },
                [_c("partners", { attrs: { partnerObj: partner } })],
                1
              )
            }),
            _vm._v(" "),
            _c("my-footer"),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }